.large-spinner.loading {
  height: 60px;
  width: 60px;
  border-width: 8px;
  border-style: solid;
  border-color: #3f4855 #3f4855 #26aebd;
  border-radius: 100%;
  animation: rotation 1s infinite linear;
}

.small-spinner.loading {
  height: 30px;
  width: 30px;
  border-width: 4px;
  border-style: solid;
  border-color: #3f4855 #3f4855 #26aebd;
  border-radius: 100%;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
