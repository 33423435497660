.input-placeholder::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #dae1e7;
  font-weight: 300;
}
.input-placeholder::-moz-placeholder {
  /* Firefox 19+ */
  color: #dae1e7;
  font-weight: 300;
}
.input-placeholder:-ms-input-placeholder {
  /* IE 10+ */
  color: #dae1e7;
  font-weight: 300;
}
.input-placeholder:-moz-placeholder {
  /* Firefox 18- */
  color: #dae1e7;
  font-weight: 300;
}

input[disabled] {
  background: #dae1e7;
}

.input-placeholder-error::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #f9acaa;
  font-weight: 300;
}
.input-placeholder-error::-moz-placeholder {
  /* Firefox 19+ */
  color: #f9acaa;
  font-weight: 300;
}
.input-placeholder-error:-ms-input-placeholder {
  /* IE 10+ */
  color: #f9acaa;
  font-weight: 300;
}
.input-placeholder-error:-moz-placeholder {
  /* Firefox 18- */
  color: #f9acaa;
  font-weight: 300;
}
