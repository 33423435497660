.small-spinner.loading {
  height: 24px;
  width: 24px;
  border-width: 2px;
  border-style: solid;
  border-color: #fefefe #fefefe #26aebd;
  border-radius: 100%;
  animation: rotation 0.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
