.input-spinner.loading {
  height: 15px;
  width: 15px;
  border-width: 2px;
  border-style: solid;
  border-color: #fefefe #fefefe #2094a1;
  border-radius: 100%;
  animation: rotation 0.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
